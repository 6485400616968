import axios from 'axios';

class UserServices {

    constructor(){
        this.urlBase = "https://excursiones.billregulator.com/api/";
    }

   
    async getUser(id){
        const response = await axios.get(this.urlBase+'users?id='+id)
        return response.data.data;
    }

}

export default UserServices;