import React, {useState} from "react";
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { IconContext } from "react-icons";
//import { BsList } from "@react-icons/all-files/fa/BsList";
import { BsList } from "react-icons/bs";
import Menu from "./Menu";
import useWindowPosition from "./Funciones";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Navegacion = () => {
    const [clicked, setClicked] = useState(false)
    const { scrollX, scrollY } = useWindowPosition()
    const handleClick = () => {
      //cunado esta true lo pasa a false y viceversa 
      setClicked(!clicked);
    }
    
    return(
        <div className={`container-menu ${scrollY > 210 ? 'background-nav' : ''}`}>
          <div className={`menu-opacity ${clicked ? 'active' : ''}`} onClick ={handleClick}></div>
            <Container fluid>
                <Navbar expand="lg" variant="light" className="pt-3">
                  <Container className="ajuste-nav">
                        <ul className="menu">
                            <li>
                                <IconContext.Provider value={{ size:"32px", color:"white" }}>
                                  <div onClick ={handleClick}>
                                    <BsList className="icon-menu"/>
                                  </div>
                                </IconContext.Provider>
                            </li>
                        </ul>
                  </Container>
                </Navbar>
            </Container>
            <Menu clicked={clicked} handleClick={handleClick}  />
        </div>
      

    )
}

export  default Navegacion