import React from "react";
import logo_w from "../../images/logo_w.png"
import img_wall_blanco from "../../images/img_wall_blanco.png"
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Slider(props) {
    return (
        <div className="container-slider">
            <div  className="container-logo-slider">
                <div className="container-logo-sslider">
                    {props.load === true && 
                        <SkeletonTheme baseColor="#afafaf" highlightColor="#d1d1d1">
                            <Skeleton   style={{ width:"100%", display:"block", height:"150px",marginBottom:"-25px"}}/>
                        </SkeletonTheme>
                    }
                    { props.load === false && 
                        <img className="logo" src={props.url} alt="" />
                    }
                    
                </div>
            </div>
            <img className="rsv-slider-separador" src={img_wall_blanco} alt="" />
        </div>
    )
}