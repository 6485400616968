
import axios from 'axios';

class HomeServices {

    constructor(){
        this.urlBase = "https://excursiones.billregulator.com/api/";
    }

    async listarReservas(id){
        const response = await axios.get(this.urlBase+'home')
        return response.data;
    }

    async getReserva(id){
        const response = await axios.get(this.urlBase+'eventos?id='+id)
        return response.data.data;
    }

}

export default HomeServices;