
import axios from 'axios';

class EventsServices {

    constructor(){
        this.urlBase = "https://excursiones.billregulator.com/api/";
    }


    async getEvents(id, date){
        const response = await axios.get(this.urlBase+'events?id='+id+'&date='+date)
        return response.data.data;
    }

}

export default EventsServices;