import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Player } from '@lottiefiles/react-lottie-player';

export default function ModalsSuccess(props) {
  

  const {show, handleClose} = props
  const playerRef = useRef(null);
  const handleEventPlayer =  (e) => {
    if (e === 'play') {
      setTimeout(() => {
        playerRef.current.pause();
      }, 1500);
      
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Player
            src='https://assets8.lottiefiles.com/packages/lf20_wkebwzpz.json'
            className="player"
            autoplay
            style={{ height: '300px', width: '300px' }}
            onEvent={handleEventPlayer}
            ref={playerRef}
        />
          <p>El pago se ha efectuado de forma exitosa!</p>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}