import React from "react";

export default  function Formularios(props){
    return(
        <div></div>
    )
}

export  function LeyendaError(props){
        return(
            <div>
                <p className="LeyendaError">Su campo {props.text} este vacío o no es válido por favor rellene </p>
            </div>
        )
}


export  function Input(props){

    const onChange = (e) =>{
        //console.log(e.target.value)
        props.cambiarEstado({...props.estado, campo: e.target.value});
    }

    const validacion = () =>{
        if (props.expresionR) {
            if(!props.expresionR.test(props.estado.campo)){
               props.cambiarEstado({...props.estado, valido: 'true'});
            }else{
                props.cambiarEstado({...props.estado, valido: 'false'});
            }
        }
    }

    return(
        <input  
        id={props.id} 
        type={props.type} 
        placeholder={props.placeholder}
        value ={props.estado.campo}
        onChange = {onChange}
        onKeyUp = {validacion}
        onBlur = {validacion}
        />
    )
}


export function Textarea(props){
    
    const onChange = (e) =>{
        //console.log(e.target.value)
        props.cambiarEstado({...props.estado, campo: e.target.value});
    }

    const validacion = () =>{
        if (props.expresionR) {
            if(!props.expresionR.test(props.estado.campo)){
               props.cambiarEstado({...props.estado, valido: 'true'});
            }else{
                props.cambiarEstado({...props.estado, valido: 'false'});
            }
        }
    }

    return(
        <textarea 
        id={props.id} 
        type={props.type} 
        placeholder={props.placeholder}
        value ={props.estado.campo}
        onChange = {onChange}
        onKeyUp = {validacion}
        onBlur = {validacion}>
        </textarea>
    )
}


export function ButtonPrimary(props){

    const onClick = (e) => {
        if(props.cambiarEstado && props.clicked){
            
            props.cambiarEstado({
                status: true,
                rango:  props.clicked
            }) 
        }else if(props.cambiarEstado){
            props.cambiarEstado(true) 
        }

        if(props.mostrarFormularioPay){
            if(props.clickBtnPay && props.clickBtnPay.length > 0){
                props.mostrarFormularioPay(true)
            }
             
        }
        
              
    }

    return(
        <button className={`btn-1 ${props.className ? props.className :''}`} onClick={onClick}>{props.title}</button>
    )
}

export function ModalSuccess(props){
    return (
        <div className="ModalSuccess">
            <div className="">

            </div>
            <div>

            </div>
            <div>

            </div>
        </div>
    )
}

