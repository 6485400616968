import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Slider from "../components/reservas/Slider";
import {ButtonPrimary} from "../components/reservas/Formularios";
import Form from "../components/reservas/Form"
import Calendar from 'react-calendar';
import ModalsSuccess from "../components/reservas/Modals";
import Direccion from "../components/reservas/Direccion";
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from "react-router-dom";
import '../components/reservas/Reservas.css';
import EventsServices  from "../services/EventsService";
import UserServices from "../services/UserServices";
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Reservas = (props) => {
  const [logoLoad,setLogoLoad] = useState(true)
  const [contendLoad,setContendLoad] = useState(true)
  const [titleLoad,settitleLoad] = useState(true)
  const [alertErrH,setalertErrH] = useState(false)
  const [hotelImg, setHotelImg] = useState("")
  const [calendar, setCalendar] = useState({
    status:false,
    rango:"",
  })
  //const [clicked, setClicked] = useState(false)
  //const [clicked30, setClicked30] = useState(false)
  const [clickBtnPay, payForm] = useState(false)
  const [clickBtnPaySucess, payFormHidden] = useState(false)
  const [open, setClickedOpen] = useState(false)
  const [idPurchases, setPurchases] = useState(null)
  const [dateValue, setDateValue] = useState(new Date());
  const [events, setEvents] = useState({
    events:{
      course_name:"",
      rangosMinutos30:{},
      rangosHora1:{},
      minutos30:{},
      hora1:{},
      rango:"",
      date:""
    },
    status: false});

  const [paramsRango, setParamsRango] = useState("")

  //constante para redireccionar
  const navigate = useNavigate();
  let { id_reserva, hotel} = useParams();

  useEffect(() => {
    
    var hotelimg = new UserServices();
        hotelimg.getUser(hotel).then((result) => {
            setHotelImg(result[0].user_avatar)
            setLogoLoad(false)
        }).catch((err) => {
          setLogoLoad(true)
        });

    //consulta para el title y descripcion segun el idioma
    setContendLoad(false)

  }, []);

  
  const handleClose = () => {
    setShow(false)
    console.log('handleClose')
    
    //redireccionamos
    navigate("/detalles-de-excursion/"+hotel+"/"+idPurchases);
    
  };
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const prueba = (e) => {
    //alert(value)
    var date = dayjs(e).format("YYYY-MM-DD")
    var events = new EventsServices()
    var event =  events.getEvents(id_reserva, date)

    event.then((element) => {
      if (element) {
        setEvents({
          events:element,
          status:true
        })
        setParamsRango("")
        setClickedOpen(true)
       

      }
    }).catch((e) => console.log(`Error ${e}`))

  }

  const  rango = (r,s) => {
    if(s){
      setalertErrH(false)
      setParamsRango(r);
    }else{
      setParamsRango("");
      setalertErrH(true)
    }
  }
  
    return(
        <div>
          <Slider url={hotelImg} load ={logoLoad} /> 
          <div className="content-pages">
            {
              contendLoad === true &&
              <SkeletonTheme baseColor="#afafaf" highlightColor="#d1d1d1">
                <Skeleton  style={{ width:"100%",height: "40px",marginBottom:"20px"}}/>
                <Skeleton  style={{ width:"100%",height: "150px",marginBottom:"20px"}}/>
                <Skeleton  style={{ width:"100%",height: "50px",marginBottom:"10px",borderRadius:"50px"}}/>
                <Skeleton  style={{ width:"100%",height: "50px",marginBottom:"20px",borderRadius:"50px"}}/>
              </SkeletonTheme>
            }
            { contendLoad === false &&
            <div>
             
              
                <div>
                  <h1 className={`${clickBtnPaySucess ? 'd-none' : ''}`}> 
                    prueba
                  </h1>
                  <div className={`${ calendar.status ? 'd-none' : ''}`}>
                    <p>Disfruta 1 ade una excursión en moto de agua, donde tu pilotadas la moto, guiado por un monitor en otra embarcación de seguridad, donde disfrutaras de la moto, la excursión y su mágica travesía con la máxima seguridad por la costa Suroeste de Gran Canaria. Las excursiones están disponibles por fracciones de 30 minutos y por horas. </p>
                  </div>
                </div>
              
            <div className={`${ !calendar.status || clickBtnPay ? 'd-none' : ''} `}>
              <Calendar 
                
                onChange={(value) => {
                  setDateValue(value)
                  prueba(value)
                }} 
                value={dateValue} 
              />
              <div> 
                  <div>
                    { alertErrH === true &&
                      <div style={{color: "#f35353",
                        textAlign: "center",
                        paddingTop: "10px",
                        marginBottom: "-11px"}}>
                        Hora no disponible
                      </div>
                    }
                    <div className={`am-appointment-times am-scroll, ${calendar.rango === "30minutes" && open ? 'open' : ''}`} >
                    {
                      Object.keys(events.events.rangosMinutos30).map(reservas => (
                      <label role="radio" tabIndex="-1" className={`el-radio-button el-radio-button--medium`} key={reservas}>
                        <input type="radio" tabIndex="-1"  className="el-radio-button__orig-radio" value="08:00"/>
                          <span onClick={() => {
                            rango(reservas,Object.keys(events.events.minutos30).filter(rangos =>  rangos === reservas).length > 0)}
                            
                            } className={`el-radio-button__inner ${paramsRango === reservas ? 'active':''} ${  Object.keys(events.events.minutos30).filter(rangos =>  rangos === reservas).length > 0 ? '' : 'reserva-disabled'}`}>
                           {reservas}
                          </span>
                        </label>
                      ))
                      
                    }
                    </div>
                    <div className={`am-appointment-times am-scroll, ${calendar.rango === "1hours" && open ? 'open' : ''}`} >
                    {
                      Object.keys(events.events.rangosHora1).map(reservas => (
                      <label role="radio" tabIndex="-1" className={`el-radio-button el-radio-button--medium`} key={reservas}>
                        <input type="radio" tabIndex="-1"  className="el-radio-button__orig-radio" value="08:00"/>
                          <span onClick={() => rango(reservas,Object.keys(events.events.minutos30).filter(rangos =>  rangos === reservas).length > 0)} className={`el-radio-button__inner ${paramsRango === reservas ? 'active':''} ${Object.keys(events.events.hora1).filter(rangos =>  rangos === reservas).length > 0 ? '' : 'reserva-disabled'}`}>
                           {reservas}
                          </span>
                        </label>
                      ))
                      
                    }
                    </div>
                  </div>
              </div>
            </div>
            {clickBtnPay === true && clickBtnPaySucess === false && <Form params ={{rango:paramsRango,date:dayjs(dateValue).format("YYYY-MM-DD")}} idPurchases ={setPurchases} handleShow = {handleShow} payFormHidden = {payFormHidden} />}
            {clickBtnPaySucess === true && <Direccion/>}
            <ModalsSuccess show={show} handleClose={handleClose} handleShow={handleShow} />
            {open === true && clickBtnPay !== true && <ButtonPrimary  className={`${paramsRango.length > 0 ? '' :'disabled'} `}  clickBtnPay={paramsRango} mostrarFormularioPay={payForm}  title="Pagar"  />}
            {calendar.status !== true && <ButtonPrimary   clicked="1hours" cambiarEstado ={setCalendar}  title="Reservar 1 hora" />}
            {calendar.status !== true && <ButtonPrimary   clicked="30minutes" cambiarEstado ={setCalendar}  title="Reservar 30 minutos" />}
            </div>
            }
            
          </div>
        </div>
    )
}
 
export default Reservas