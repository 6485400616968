import React, {useState,} from "react";
import { useParams } from "react-router-dom";
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import './Reservas.css'
import {LeyendaError, Input } from './Formularios'
import PurchaseServices from "../../services/PurchaseServices";
export default function Form(props) {



        const expresiones = {
            Nombre: /^\s*$/
        } 

        const options = [
            {name: 'Hotel España', value: 'sv'},
            {name: 'Hotel Madrid', value: 'en'},
            
        ];
        
        const [Nombre, cambiarNombre]       = useState({campo:"", valido: null});
        const [Apellidos, cambiarApellido]  = useState({campo:"", valido: null});
        const [Correo, cambiarCorreo]       = useState({campo:"", valido: null});
        const [Lugar, cambiarLugar]         = useState({campo:"", valido: null});
        const [Personas, cambiarPersonas]   = useState({campo:"", valido: null});
        const [showModal, setPay] = useState(false)
        let { id, id_reserva } = useParams();
      
        var opts = {disabled:""};
        var tActivo = false 

        const ActionPay = (e) => {

            if(Nombre.valido === "true" && Apellidos.valido === "true" && Correo.valido === "true" && Lugar.valido === "true" && Personas.valido === "true"){
                opts['disabled'] = '';
                tActivo = true


                setPay(true)
                var  prueba = true

                //aqui hacemos la consulta y ingresamos el pago 
                var query = new PurchaseServices()
                var purchases = query.storePurchases({
                    idPurchases:id_reserva,
                    hotel:id,
                    cupon:"14",
                    method:"en mano",
                    price:"70",
                    range_hours:props.params.rango,
                    date:props.params.date,
                    nombre:Nombre.campo,
                    apellido:Apellidos.campo,
                    correo: Correo.campo,
                    lugar: Lugar.campo,
                    personas:Personas.campo
                });

                purchases.then((element) => {

                    console.log(element.data.token)
                    if(element.code === 201){
                            props.idPurchases(element.data.token)
                            props.handleShow(true)
                        //props.payFormHidden(true)
                    }

                })

               

            } else{
               if(Nombre.campo.length < 1){
                cambiarNombre({campo:Nombre.campo, valido:"false"})
               }
               if(Apellidos.campo.length < 1){
                cambiarApellido({campo:Apellidos.campo, valido:"false"})
               }
               if(Correo.campo.length < 1){
                cambiarCorreo({campo:Correo.campo, valido:"false"})
               }
               if(Lugar.campo.length < 1){
                cambiarLugar({campo:Lugar.campo, valido:"false"})
               }

               if(Personas.campo.length < 1){
                cambiarPersonas({campo:Personas.campo, valido:"false"})
               }
            }
            
        }
        
        return (
            <div className="form-reserva">
                <div>
                    <div className="input-form">
                        <label>Nombre</label>
                        <div className="form-input">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon s-ion-icon" viewBox="0 0 512 512"><title>Person</title><path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z"></path></svg>
                            <Input placeholder="Nombre" type="text" estado={Nombre} cambiarEstado={cambiarNombre} expresionR = {expresiones.Nombre} />
                            { Nombre.valido === "false" && <LeyendaError text="Nombre" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Apellidos</label>
                        <div className="form-input">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon s-ion-icon" viewBox="0 0 512 512"><title>Person</title><path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z"></path></svg>
                            <Input placeholder="Apellidos" type="text" estado={Apellidos} cambiarEstado={cambiarApellido} expresionR = {expresiones.Nombre}/>
                            { Apellidos.valido === "false" && <LeyendaError text="Apellidos" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Correo</label>
                        <div className="form-input">
                        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 75.294 75.294" ><g><path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                            <Input placeholder="Correo" type="text" estado={Correo} cambiarEstado={cambiarCorreo} expresionR = {expresiones.Nombre} />
                            { Correo.valido === "false" && <LeyendaError text="Correo" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Lugar de recogida</label>
                        <div className="form-input">
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.13 362.13"  >
                          <g>
                            <path d="m181.065,0c-75.532,0-136.981,61.45-136.981,136.981 0,31.155 21.475,76.714 63.827,135.411 30.619,42.436 60.744,75.965 62.011,77.372l11.144,12.367 11.144-12.367c1.267-1.406 31.392-34.936 62.011-77.372 42.352-58.697 63.827-104.255 63.827-135.411-0.001-75.531-61.451-136.981-136.983-136.981zm0,316.958c-37.733-44.112-106.981-134.472-106.981-179.977 0-58.989 47.991-106.981 106.981-106.981s106.981,47.992 106.981,106.981c0.001,45.505-69.248,135.865-106.981,179.977z"/>
                            <circle cx="181.065" cy="136.982" r="49.696"/>
                          </g>
                        </svg>
                            <Input placeholder="Lugar de recogida" type="text" estado={Lugar} cambiarEstado={cambiarLugar} expresionR = {expresiones.Nombre}/>
                            { Lugar.valido === "false" && <LeyendaError text="Lugar de recogida" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Hotel</label>
                        <div className="form-input">
                        <svg style={{zIndex : "2"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.13 362.13"  >
                          <g>
                            <path d="m181.065,0c-75.532,0-136.981,61.45-136.981,136.981 0,31.155 21.475,76.714 63.827,135.411 30.619,42.436 60.744,75.965 62.011,77.372l11.144,12.367 11.144-12.367c1.267-1.406 31.392-34.936 62.011-77.372 42.352-58.697 63.827-104.255 63.827-135.411-0.001-75.531-61.451-136.981-136.983-136.981zm0,316.958c-37.733-44.112-106.981-134.472-106.981-179.977 0-58.989 47.991-106.981 106.981-106.981s106.981,47.992 106.981,106.981c0.001,45.505-69.248,135.865-106.981,179.977z"/>
                            <circle cx="181.065" cy="136.982" r="49.696"/>
                          </g>
                        </svg>
                            <SelectSearch options={options} value="sv" name="language" placeholder="Choose your language" search/>
                            { Lugar.valido === "false" && <LeyendaError text="Hotel" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Numero de personas</label>
                        <div className="form-input">
                            <svg  viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.2359 10.0547C3.69336 10.8685 3.40385 11.8247 3.40385 12.8028L3.40385 13.5C3.40385 14.0522 2.95614 14.5 2.40385 14.5C1.85157 14.5 1.40385 14.0522 1.40385 13.5L1.40385 12.8028C1.40385 11.4299 1.81023 10.0877 2.5718 8.94531L4.2359 10.0547Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.20663 9C5.41471 9 4.67519 9.39578 4.23591 10.0547L2.57181 8.9453C3.38202 7.72998 4.74601 7 6.20663 7H6.40386C6.95614 7 7.40386 7.44772 7.40386 8C7.40386 8.55228 6.95614 9 6.40386 9H6.20663Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.41797 10.0547C8.96051 10.8685 9.25002 11.8247 9.25002 12.8028L9.25002 13.5C9.25002 14.0522 9.69773 14.5 10.25 14.5C10.8023 14.5 11.25 14.0522 11.25 13.5L11.25 12.8028C11.25 11.4299 10.8436 10.0877 10.0821 8.94531L8.41797 10.0547Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.44724 9C7.23916 9 7.97868 9.39578 8.41796 10.0547L10.0821 8.9453C9.27185 7.72998 7.90786 7 6.44724 7H6.25001C5.69773 7 5.25001 7.44772 5.25001 8C5.25001 8.55228 5.69773 9 6.25001 9H6.44724Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.25 6.25C6.94036 6.25 7.5 5.69036 7.5 5C7.5 4.30964 6.94036 3.75 6.25 3.75C5.55964 3.75 5 4.30964 5 5C5 5.69036 5.55964 6.25 6.25 6.25ZM6.25 8.25C8.04493 8.25 9.5 6.79493 9.5 5C9.5 3.20507 8.04493 1.75 6.25 1.75C4.45507 1.75 3 3.20507 3 5C3 6.79493 4.45507 8.25 6.25 8.25Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.4859 13.3047C10.9434 14.1185 10.6538 15.0747 10.6539 16.0528L10.6539 16.75C10.6539 17.3022 10.2061 17.75 9.65385 17.75C9.10157 17.75 8.65385 17.3022 8.65385 16.75L8.65385 16.0528C8.65385 14.6799 9.06023 13.3377 9.8218 12.1953L11.4859 13.3047Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.4566 12.25C12.6647 12.25 11.9252 12.6458 11.4859 13.3047L9.82181 12.1953C10.632 10.98 11.996 10.25 13.4566 10.25H13.6539C14.2061 10.25 14.6539 10.6977 14.6539 11.25C14.6539 11.8023 14.2061 12.25 13.6539 12.25H13.4566Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.668 13.3047C16.2105 14.1185 16.5 15.0747 16.5 16.0528L16.5 16.75C16.5 17.3022 16.9477 17.75 17.5 17.75C18.0523 17.75 18.5 17.3022 18.5 16.75L18.5 16.0528C18.5 14.6799 18.0936 13.3377 17.3321 12.1953L15.668 13.3047Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.6972 12.25C14.4892 12.25 15.2287 12.6458 15.668 13.3047L17.3321 12.1953C16.5219 10.98 15.1579 10.25 13.6972 10.25H13.5C12.9477 10.25 12.5 10.6977 12.5 11.25C12.5 11.8023 12.9477 12.25 13.5 12.25H13.6972Z" fill="#00aed8"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.5 9.5C14.1904 9.5 14.75 8.94036 14.75 8.25C14.75 7.55964 14.1904 7 13.5 7C12.8096 7 12.25 7.55964 12.25 8.25C12.25 8.94036 12.8096 9.5 13.5 9.5ZM13.5 11.5C15.2949 11.5 16.75 10.0449 16.75 8.25C16.75 6.45507 15.2949 5 13.5 5C11.7051 5 10.25 6.45507 10.25 8.25C10.25 10.0449 11.7051 11.5 13.5 11.5Z" fill="#00aed8"/>
                            </svg>
                            <Input placeholder="Numero de personas" type="number" estado={Personas} cambiarEstado={cambiarPersonas} expresionR = {expresiones.Nombre}/>
                            { Personas.valido === "false" && <LeyendaError text="Numero de personas" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Cupones</label>
                        <div className="form-input">
                            <svg xmlns="http://www.w3.org/2000/svg" 
                            	 viewBox="0 0 248.774 248.774">
                                <g>
                                	<g>
                                		<g>
                                			<path d="M219.179,51.502H29.6C13.277,51.502,0,63.539,0,78.335v92.104c0,14.796,13.277,26.833,29.6,26.833h189.577
                                				c16.318,0,29.597-12.037,29.597-26.833V78.335C248.774,63.539,235.495,51.502,219.179,51.502z M233.557,170.439
                                				c0,6.404-6.455,11.616-14.38,11.616H29.6c-7.931,0-14.383-5.212-14.383-11.616V78.335c0-6.404,6.452-11.616,14.383-11.616
                                				h189.577c7.926,0,14.38,5.212,14.38,11.616V170.439z"/>
                                			<rect x="67.622" y="104.742" width="15.217" height="13.097"/>
                                			<rect x="67.622" y="157.127" width="15.217" height="13.097"/>
                                			<rect x="67.622" y="78.548" width="15.217" height="13.097"/>
                                			<rect x="67.622" y="130.936" width="15.217" height="13.094"/>
                                			<path d="M152.026,103.507c0-11.874-9.858-21.611-21.616-21.611c-11.874,0-21.613,9.62-21.613,21.611
                                				c0,12.115,9.741,21.613,21.613,21.613C142.168,125.12,152.026,115.503,152.026,103.507z M121.029,103.507
                                				c0-5.225,4.276-9.379,9.379-9.379s9.379,4.157,9.379,9.379c0,5.227-4.276,9.381-9.379,9.381
                                				C125.305,112.888,121.029,108.731,121.029,103.507z"/>
                                			<path d="M186.189,124.41c-11.874-0.043-21.651,9.544-21.697,21.537c-0.041,12.11,9.665,21.649,21.542,21.695
                                				c11.76,0.043,21.646-9.546,21.692-21.537C207.767,134.227,197.947,124.45,186.189,124.41z M186.108,155.407
                                				c-5.103,0-9.376-4.162-9.376-9.384c0-5.227,4.276-9.381,9.376-9.381c5.108,0,9.381,4.157,9.381,9.381
                                				C195.492,151.248,191.216,155.407,186.108,155.407z"/>

                                				<rect x="109.054" y="117.595" transform="matrix(0.4696 -0.8829 0.8829 0.4696 -26.7873 205.8196)" width="97.7" height="15.217"/>
                                		</g>
                                	</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                            </svg>
                            <input placeholder="Cupon"/>
                        </div>
                    </div>
                </div>
                {tActivo && <div className="container-price">
                    <span className="moneda"></span>
                    <span className="price">70€</span>
                </div>}
                <div>
                    <button {...opts} onClick={ActionPay} className="btn-1" >Pagar</button>
                </div>
               
            </div>
        )
    
}




