import React ,{Component}from "react";
import { Link, useParams, on } from "react-router-dom";
import t_1 from "../images/t_1.png"
import img_1 from "../images/img_1.png"
import '../components/home/Home.css'
import HomeServices  from "../services/HomeServices";
import UserServices  from "../services/UserServices";
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

class Home extends Component {

  constructor(props){
    super(props);

    this.state = {
      data: [],
      user:[{
        user_avatar:""
      }],
      itemsload:"block",
      logoload:"block"
    }
    
    this.userId = window.location.href.split('/')[3]

  }

  async componentDidMount(){
    localStorage.setItem('hotel', JSON.stringify(this.userId))
    var prueba = new HomeServices()
    prueba.listarReservas().then((result) => {
      //console.log(result)
      if(result.code === 200){
        this.setState({data : result.data});
        this.setState({itemsload : "none"});
      }
     
    }).catch((err) => {
      console.log(err)
      this.setState({itemsload : "block"});
    });
    var user = new UserServices();
    
    user.getUser(this.userId).then((result) => {
      this.setState({user :result});
      this.setState({logoload : "none"});
    }).catch((err) => {
      this.setState({logoload : "block"});
    });
    //console.log(this.state.user[0].user_avatar);
  }

  
  
 
  render() {

    return(
        <div  className="slider">
          <div style={{marginLeft:"auto",marginRight:"auto",marginTop:"80px",width:"170px",display:this.state.logoload}}>
          <SkeletonTheme baseColor="#afafaf" highlightColor="#d1d1d1">
            <Skeleton  circle style={{ width:"170px",height: "170px"}}/>
          </SkeletonTheme>
          </div>
          { this.state.logoload === "none" &&
           <div className="qr">
            <Link to="/reservas" className="a">
              <div className="qr-content" style={{backgroundImage: `url(${this.state.user[0].user_avatar})` }}>
                  <div className="capa">

                  </div>
                 
              </div>
              <div className="qr-btn-ver">
                <img src={img_1} className="" alt=""/>
              </div>
              
            </Link>
          </div>
          }
          <div className="content">
            <SkeletonTheme baseColor="#afafaf" highlightColor="#d1d1d1">
              <div style={{display:this.state.itemsload}}>
              <div style={{display: "flex"}}>
                <div style={{width: "80px"}}>
                  <Skeleton style={{ width:"100%",height: "70px",marginBottom:"20px"}}/>
                </div>
                <div style={{width: "80%",marginLeft: "5px"}}>
                  <Skeleton style={{ width:"100%",height: "70px",marginBottom:"20px"}}/>
                </div>
              </div>
              <div style={{display: "flex"}}>
                <div style={{width: "80px"}}>
                  <Skeleton style={{ width:"100%",height: "70px",marginBottom:"20px"}}/>
                </div>
                <div style={{width: "80%",marginLeft: "5px"}}>
                  <Skeleton style={{ width:"100%",height: "70px",marginBottom:"20px"}}/>
                </div>
              </div>
              <div style={{display: "flex"}}>
                <div style={{width: "80px"}}>
                  <Skeleton style={{ width:"100%",height: "70px",marginBottom:"20px"}}/>
                </div>
                <div style={{width: "80%",marginLeft: "5px"}}>
                  <Skeleton style={{ width:"100%",height: "70px",marginBottom:"20px"}}/>
                </div>
              </div>
              </div>
              
              
            </SkeletonTheme>
            
          
          { this.state.itemsload === "none" &&
              this.state.data.map(reservas => (
              <div className="banner-home" key= {reservas.course_id}>
              <div className="banner-slider">
                <img src={t_1} className="banner-img" alt="" />
              </div>
              <div className="banner-content">
                <div className="banner-content-body">
                    <div><h2>{reservas.course_name}</h2></div>
                    <div> 
                      <Link to={`/reservas/${this.userId}/${reservas.course_id}`}>
                      <button className="banner-button">Reservar</button>
                      </Link>
                    </div>
                </div>
              </div>
            </div>
              )
            )
            }
          
          </div>
          
        </div>
    )
}
}

export default Home