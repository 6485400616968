import axios from 'axios';

class PurchaseServices {

    constructor(){
        this.urlBase = "https://excursiones.billregulator.com/api/purchases";
    }

   
    async getPurchases(id){
        const response = await axios.get(this.urlBase +'?id='+id)
        return response.data;
    }

    async storePurchases(params){
        const response = await axios.post(this.urlBase,{data:params})
        return response.data;
    }

}

export default PurchaseServices;