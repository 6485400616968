import './App.css';
import { BrowserRouter , Route, Routes} from "react-router-dom";
import Reservas from './pages/Reservas';
import Home from './pages/Home';
import Navegacion from './components/Navegacion';
import Menu from './components/Menu';
import Contacto from './pages/Contacto';
import DetallePage from './pages/Direccion'
import PoliticaDePrivacidad from './pages/Politica_de_privacidad';

function App() {
  return (
    <BrowserRouter>
      <Menu/>
      <Navegacion />

      <Routes>
        <Route  path="/:hotel" element={<Home/>} />
        <Route  path="/reservas/:hotel/:id_reserva" element={<Reservas/>} />
        <Route  path="/contacto" element={<Contacto/>} />
        <Route  path="/detalles-de-excursion/:hotel/:id" element={<DetallePage/>} />
        <Route  path="/politica-de-privacidad" element={<PoliticaDePrivacidad/>} />
      </Routes>
    </BrowserRouter>
    
    
  );
}

export default App;
