import React from "react";
import Slider from "../components/reservas/Slider";
import '../components/reservas/Reservas.css';
const PoliticaDePrivacidad = () => {

    return(
        <div>
          <Slider/>
          <div className="content-pages">
            <h1>Politica de Privacidad</h1>
            <p>Disfruta 1 de una excursión en moto de agua, donde tu pilotadas la moto, guiado por un monitor en otra embarcación de seguridad, donde disfrutaras de la moto, la excursión y su mágica travesía con la máxima seguridad por la costa Suroeste de Gran Canaria. Las excursiones están disponibles por fracciones de 30 minutos y por horas. </p>
           
          </div>
          
        </div>
    )
}

export default PoliticaDePrivacidad