import React from "react";
import Slider from "../components/reservas/Slider";
import '../components/reservas/Reservas.css';
import Form from "../components/contacto/Form";
const Contacto = () => {

    return(
        <div>
          <Slider/>
          <div className="content-pages">
            <h1>Contacto</h1>
            <Form/>
          </div>
          
        </div>
    )
}

export default Contacto