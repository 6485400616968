import React, {useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import Slider from "../components/reservas/Slider";
import Direccion from "../components/reservas/Direccion";
import '../components/reservas/Reservas.css';
import PurchaseServices from "../services/PurchaseServices";
import UserServices from "../services/UserServices";



const DetallePage = (props) =>{
    const [hotelImg, setHotelImg] = useState("")
    const [params, setParams] = useState({
        "id": "",
        "purchases_product": "",
        "hotel_id": "",
        "purchases_coupon": "",
        "purchases_name": "",
        "purchases_surname": "",
        "purchases_email": "",
        "date": "",
        "range_hours": "",
        "purchases_method": "",
        "purchases_price": "0",
        "purchases_number_people": "0",
        "token": "",
        "purchases_date": "",
        "excursion": {
            "course_name": "",
            "course_price": ""
        }
    })
    let {hotel, id } = useParams();

    useEffect(() => {
        
        var hotelimg = new UserServices();
        hotelimg.getUser(hotel).then((result) => {
            setHotelImg(result[0].user_avatar)
        }).catch((err) => {
            
        });
        
        

        var query = new PurchaseServices()
        query.getPurchases(id).then((element) => {
            setParams(element.data)
        });
        
    }, []);
    
    
    return(
        <>
            <Slider url={hotelImg}/>
            <div className="content-pages">
                <Direccion params = {params}/>
            </div>
        </>
    )
}

export default DetallePage