import React, {useState} from "react";
import '../reservas/Reservas.css'
import {LeyendaError, Input, Textarea} from '../reservas/Formularios'

export default function Form(props) {
        const expresiones = {
            Nombre: /^\s*$/
        } 
        const [Nombre, cambiarNombre] = useState({campo:"", valido: null});
        const [Apellidos, cambiarApellido] = useState({campo:"", valido: null});
        const [Correo, cambiarCorreo] = useState({campo:"", valido: null});
        const [Asunto, cambiarAsunto] = useState({campo:"", valido: null});
        const [Mensaje, cambiarMensaje] = useState({campo:"", valido: null});
        
        var opts = {disabled:"disabled"};
       // var tActivo = false 
        if(Nombre.valido === "true" && Apellidos.valido === "true" && Correo.valido === "true" && Asunto.valido === "true" && Mensaje.valido === "true"){
            opts['disabled'] = '';
           // tActivo = true
        }
        
        return (
            <div className="form-reserva">
                <div>
                    <div className="input-form">
                        <label>Nombre</label>
                        <div className="form-input">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon s-ion-icon" viewBox="0 0 512 512"><title>Person</title><path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z"></path></svg>
                            <Input placeholder="Nombre" type="text" estado={Nombre} cambiarEstado={cambiarNombre} expresionR = {expresiones.Nombre} />
                            { Nombre.valido === "false" && <LeyendaError text="Nombre" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Apellidos</label>
                        <div className="form-input">
                            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon s-ion-icon" viewBox="0 0 512 512"><title>Person</title><path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z"></path></svg>
                            <Input placeholder="Apellidos" type="text" estado={Apellidos} cambiarEstado={cambiarApellido} expresionR = {expresiones.Nombre}/>
                            { Apellidos.valido === "false" && <LeyendaError text="Apellidos" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Correo</label>
                        <div className="form-input">
                        <svg  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 75.294 75.294" ><g><path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                            <Input placeholder="Correo" type="text" estado={Correo} cambiarEstado={cambiarCorreo} expresionR = {expresiones.Nombre} />
                            { Correo.valido === "false" && <LeyendaError text="Correo" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Asunto</label>
                        <div className="form-input">
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.13 362.13"  >
                          <g>
                            <path d="m181.065,0c-75.532,0-136.981,61.45-136.981,136.981 0,31.155 21.475,76.714 63.827,135.411 30.619,42.436 60.744,75.965 62.011,77.372l11.144,12.367 11.144-12.367c1.267-1.406 31.392-34.936 62.011-77.372 42.352-58.697 63.827-104.255 63.827-135.411-0.001-75.531-61.451-136.981-136.983-136.981zm0,316.958c-37.733-44.112-106.981-134.472-106.981-179.977 0-58.989 47.991-106.981 106.981-106.981s106.981,47.992 106.981,106.981c0.001,45.505-69.248,135.865-106.981,179.977z"/>
                            <circle cx="181.065" cy="136.982" r="49.696"/>
                          </g>
                        </svg>
                            <Input placeholder="Asunto" type="text" estado={Asunto} cambiarEstado={cambiarAsunto} expresionR = {expresiones.Nombre}/>
                            { Asunto.valido === "false" && <LeyendaError text="Asunto" />}
                        </div>
                    </div>
                    <div className="input-form">
                    <label>Mensaje</label>
                        <div className="form-input">
                            <Textarea  placeholder="Mensaje" type="textare" estado={Mensaje} cambiarEstado={cambiarMensaje} expresionR = {expresiones.Nombre}/>
                            { Mensaje.valido === "false" && <LeyendaError text="Mensaje" />}
                        </div>
                    </div>
                    <button {...opts} className="btn-1">ENVIAR</button>
                </div>
            </div>
        )
    
}