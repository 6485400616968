import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ButtonPrimary } from "./Formularios";
import { Link,useParams } from "react-router-dom";
import 'react-select-search/style.css'
import './Reservas.css'


export default function Direccion(props) {

    
  
    let { hotel, id} = useParams();

    return (
        <>
                <Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Nombre </Form.Label>
                            <div>
                                {props.params.purchases_name}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Apellido</Form.Label>
                            <div>
                                {props.params.purchases_surname}
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Servicio</Form.Label>
                            <div>
                                {props.params.excursion.course_name}
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Fecha</Form.Label>
                            <div>
                            {props.params.date}
                            </div>
                        </Form.Group>
                     </Col>
                     <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Hora de recogida</Form.Label>
                            <div>
                                10:25 am
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Lugar de recogida</Form.Label>
                            <div>
                                lorem lroe lorem lroe lorem lroe lorem lroe lorem lroe
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                <Row>
                     <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Lugar de Destino</Form.Label>
                            <div>
                                lorem lroe lorem lroe lorem lroe lorem lroe lorem lroe
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Numero de personas </Form.Label>
                            <div>
                                {props.params.purchases_number_people}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Precio por persona</Form.Label>
                            <div>
                                {props.params.purchases_price} €
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Total pagado </Form.Label>
                            <div>
                                {props.params.purchases_price * props.params.purchases_number_people} €
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                    
                <Row>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Hora de inicio /fin </Form.Label>
                            <div>
                                {props.params.range_hours}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3 Cld">
                            <Form.Label>Metodo de pago</Form.Label>
                            <div>
                                {props.params.purchases_method}
                            </div>
                        </Form.Group>
                     </Col>
                </Row>
                <Link to={`/${hotel}`} className="btn-1">Volver a la home</Link>
            
        </>
    )
}