import React, {useState,useEffect} from "react";
import { NavLink } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import logo_b from "../images/logo_b.png"
import ic_8 from "../images/ic_8.png"
import ic_3 from "../images/ic_3.png"
import ic_2 from "../images/ic_2.png"
import ic_5 from "../images/ic_5.png"
import ic_6 from "../images/ic_6.png"
import ic_7 from "../images/ic_7.png"


export default function Menu(props){
    
    const [hotel, setHotel] = useState(null);
    //console.log(window.location.href.split('/')[4])
    useEffect(() => {
        var idHotel =  JSON.parse(localStorage.getItem('hotel'));
        setHotel(idHotel);
       
        console.log(localStorage.getItem('hotel'));
    },[]);

    
        const [selected, setSelected] = useState("ES");
        
        return (<div className={`container-menu-nav ${props.clicked ? 'active' : ''}`}>
            <ul>
                <div className="container-close-menu">
                    <img className="close" src={ic_8} alt="" onClick ={props.handleClick} />
                </div>
                <div className="container-logo">
                    <img className="logo" src={logo_b} alt="" />
                </div>
                <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    className="li-element"
                    selectButtonClassName="menu-flags-button"
                    searchable
                    optionsSize={16}
                />
            
                <NavLink to={`/${hotel}`} className="li-element" onClick ={props.handleClick}>
                    <img src={ic_3} className="li-icons" alt="" /> Reservas
                </NavLink>
                <NavLink to={`/politica-de-privacidad/`} className="li-element" onClick ={props.handleClick}>
                    <img src={ic_2} className="li-icons" alt="" /> Politica de Privacidad
                </NavLink>
                <NavLink to="/contacto" className="li-element" onClick ={props.handleClick}>
                    <img src={ic_3} className="li-icons" alt="" /> Contacto
                </NavLink>
                
                <div className="redes-sociales">
                    <a target="_blank" href="https://www.facebook.com/" rel="noreferrer"><img src={ic_5} alt="" /></a>
                    <a target="_blank" href="https://www.facebook.com/" rel="noreferrer"><img src={ic_6} alt="" /></a>
                    <a  target="_blank" href="https://www.facebook.com/" rel="noreferrer"><img width="30px" src={ic_7} alt="" /></a>
                </div>
            </ul>
        </div>);
    
}